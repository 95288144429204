<template>
      <div class="sections-container">

    <div class="section-container">
      <h1>Convert Word</h1>
      <div style="text-align: center;">

      <form @submit.prevent="convertWord">
        <div style="text-align: center;">

        <input v-model="word" placeholder="Enter a word" class="input-field" ref="inputField" />
        </div>
      </form>
      <p v-if="error" class="error-text">{{ error }}</p>
      <div class="button-group">
        <button @click="convertWord" class="convert-button">Convert to 3x3 Scramble</button>
        <button 
          v-if="showCopyButton" 
          @click="copyURL" 
          class="copy-button" 
          :title="copyTooltip">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Copy</title>
            <desc>A solid styled icon from Orion Icon Library.</desc>
            <path data-name="layer2" d="M14 14h50v50H14z"></path>
            <path data-name="layer1" d="M50 0H0v50h10V10h40V0z"></path>
          </svg>
        </button>
      </div>
    
      <p v-if="result" class="result-text">{{ result }}</p>
      <p v-if="convertedWord" class="converted-word">({{ convertedWord }})</p>
      </div>
  </div>
  <div class="section-container">

    <TimerComponent v-if="timerEnabled" ref="timerComponent" />
  </div>

</div>

</template>

<script>
import axios from '../axios';
import TimerComponent from './TimerComponent.vue';


export default {
  components: {
    TimerComponent
  },
  props: {
    timerEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      word: '',
      result: '',
      error: '', // State to handle error messages
      copyTooltip: 'Copy URL to share word',
      converted: false, // State to track if the word has been converted
      convertedWord: '', // State to store the converted word
      imageSrc: require('@/assets/WormbleRe.png'),
      showTimer: false // Initially hide the timer
    };
  },
  computed: {
    showCopyButton() {
      // Show the copy button only if a word has been loaded from the URL or the convert button has been clicked
      return this.converted;
    }
  },
  methods: {
    async convertWord() {
      this.$refs.inputField.blur()
      if (this.word.length > 100) {
        this.error = 'Word must be 100 characters or less.';
        this.result = '';
        this.converted = false;
        return;
      }

      try {
        this.error = ''; // Clear previous errors
        const response = await axios.post('/convert', { word: this.word });
        if (response.data.error) {
          this.error = response.data.error;
          this.result = ''; // Clear previous result if there's an error
          this.converted = false; // Ensure the copy button is hidden
        } else {
          this.result = response.data.scramble;
          this.convertedWord = decodeURIComponent(this.word); // Store the converted word
          this.updateURL();
          this.converted = true; // Set converted to true after successful conversion
        }
      } catch (error) {
        console.error(error);
      }
    },
    toggleTimer() {
      this.showTimer = !this.showTimer;
    },
    updateURL() {
      const newUrl = `${window.location.origin}/?w=${encodeURIComponent(this.word)}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    },
    async loadWordFromURL() {
      const params = new URLSearchParams(window.location.search);
      const word = params.get('w');
      if (word) {
        if (word.length > 100) {
          this.error = 'Word must be 100 characters or less.';
          this.result = '';
          this.converted = false;
          return;
        }

        this.word = word;
        try {
          const response = await axios.post('/convert', { word: this.word });
          if (response.data.error) {
            this.error = response.data.error;
            this.result = ''; // Clear previous result if there's an error
            this.converted = false; // Ensure the copy button is hidden
          } else {
            this.result = response.data.scramble;
            this.convertedWord = this.word; // Store the converted word
            this.converted = true; // Set converted to true if the word is loaded from URL
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    copyURL() {
    const urlToCopy = window.location.href;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(urlToCopy).then(() => {
        this.copyTooltip = 'Copied!';
        setTimeout(() => {
          this.copyTooltip = 'Copy URL to share word';
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      // Fallback for older browsers or insecure connections
      const textArea = document.createElement('textarea');
      textArea.value = urlToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        this.copyTooltip = 'Copied!';
        setTimeout(() => {
          this.copyTooltip = 'Copy URL to share word';
        }, 2000);
      } catch (err) {
        console.error('Failed to copy using fallback: ', err);
      }
      document.body.removeChild(textArea);
    }
  }
  },
  mounted() {
    this.loadWordFromURL();
    //alert(timerEnabled);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

h1 {
  color: #1697B7; /* Bright orange color */
  margin-bottom: 10px;
  margin-top: 0;

}

.container {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent; /* Make the background transparent */
  padding: 10px;
  overflow-y: auto;

}

.title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
    overflow-y: auto;

}



.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  position: relative;
}



.input-field {
  width: calc(100% - 10px);  /*Adjust width to fill the container with some padding */
  max-width: 500px; /* Maximum width for larger screens */
  padding: 10px;
  margin: 0 auto; /* Add this line */
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.0em;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  position: relative;
}

.error-text {
  color: #ffb18e;
  font-size: 0.9em;
  margin-top: -10px;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  margin-top: 10px; /* Add some space between the input and buttons */
  width: 100%; /* Make the button group take the full width of its container */
}

.convert-button {
  padding: 10px 20px;
  background-color: #1697B7;
  color: #ffffff; /* Dark grey text color */
  border: 2px solid #1697B7;
  border-radius: 5px;
  font-size: 1.0em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  margin: 0 5px; /* Add margin to separate the buttons */
}

.convert-button:hover {
  background-color: #1697B7;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px;
  transition: transform 0.3s ease;
}

.copy-button:hover {
  transform: scale(1.1);
}

.copy-button svg {
  width: 20px;
  height: 20px;
}

.copy-button svg path {
  fill: #1697B7; /* Set the fill color to match the convert button */
}

.result-text {
  font-size: 1.5em;
  margin-top: 20px;
  color: #1697B7;
}

.converted-word {
  font-size: 0.9em;
  color: #1697B7;
  margin-top: 10px;
}

.sections-container {
  max-width: 90%; /* adjust this value as needed */
  padding: 20px;
  width: 100%;
  margin: 20px auto; /* adjust the top and bottom margins as needed */

}
.section-container {
  max-width: 90%; /* adjust this value as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto; /* adjust the top and bottom margins as needed */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
