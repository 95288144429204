// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App);

app.use(router);

app.use(vue3GoogleLogin, {
  clientId: '626066527308-ma7bcpkbd40aih7k2su7vrpbpe1ong4o.apps.googleusercontent.com'
})

app.mount('#app');