<template>
  <div id="app">
    <div class="header-container">
      <img :src="imageSrc" alt="Wormble" class="title-image" @click="goToHomePage">
    </div>
    <MenuComponent @toggleTimer="toggleTimer" />
    <router-view :timerEnabled="timerEnabled" />
    <AdvertBanner />
  </div>
</template>

<script>
import MenuComponent from './components/MenuComponent.vue';
import AdvertBanner from './components/AdvertBanner.vue';


export default {
  name: 'App',
  components: {
    MenuComponent,
    AdvertBanner,
  },
  data() {
    return {
      imageSrc: require('@/assets/WormbleRe.png'),
      showAbout: false,
      timerEnabled: localStorage.getItem('timerEnabled') !== null ? localStorage.getItem('timerEnabled') === 'true' : true,
    };
  },
  methods: {
    showAboutPage() {
      this.showAbout = true;
    },
    toggleTimer(timerEnabled) {
      this.timerEnabled = timerEnabled;
    },
    goToHomePage() {
      this.$router.push({ name: 'ConvertForm' });
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to bottom, #6e6e6e, #ffffff);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  overflow-y: auto;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  #app {
    align-items: center;
    justify-content: flex-start;
  }
}

.header-container {
  background-color: #333; /* Dark grey background */
  padding: 0px; /* Add some padding to the container */
  text-align: center; /* Center the image horizontally */
  width: 100vw; /* Take up the full width of the viewport */
  height: 200px; /* Set the height of the container */
  margin: 0; /* Remove the horizontal margin */
  position: relative; /* Remove from normal document flow */
  margin-top: 0; /* Position at the top of the viewport */
  left: 0; /* Position at the left of the viewport */
}

.title-image {
  width: 80%; /* Set the width of the image */
  max-width: 400px;
  height: auto; /* Set the height of the image to auto */
  margin: 0 auto; /* Center the image horizontally */
  margin-bottom: 20px;
  cursor: pointer; /* Add a cursor pointer to indicate clickable image */
  position: absolute; /* Position the image absolutely */
  top: 50%; /* Position the image 50% from the top */
  left: 50%; /* Position the image 50% from the left */
  transform: translate(-50%, -50%); /* Center the image horizontally and vertically */
}


</style>
