<template>
  <div class="timer">
    <div v-if="!editing" @dblclick="startEditing" class="display">
      <span v-if="showMinutes">{{ displayMinutes }}:</span>{{ displaySeconds }}.{{ displayHundredths }}
      <div :class="['status-circle', circleState]"></div>
    </div>
    <div v-else class="display input-wrapper">
      <input ref="input" v-model="manualTime" @blur="stopEditing" @keydown.enter="stopEditing" type="text" class="timer-input" />
    </div>
    <a href="#" class="reset-link" @click="resetTimer">Reset</a>
  </div>
</template>

<script>
export default {
  name: 'TimerComponent',
  data() {
    return {
      startTime: null,
      elapsedTime: 0,
      timerInterval: null,
      running: false,
      awaitingStart: true,
      expectingStop: false,
      holdTimeout: null,
      holdStart: false,
      resetPending: false,
      keyPressed: false,
      editing: false,
      manualTime: '',
      formattedTime: '00:00:00',
      justDidTime: false,
    };
  },
  computed: {
    displayMinutes() {
      return this.pad(Math.floor(this.elapsedTime / 60000));
    },
    displaySeconds() {
      const seconds = Math.floor((this.elapsedTime % 60000) / 1000);
      return seconds >= 10 || this.showMinutes ? this.pad(seconds) : seconds.toString();
    },
    displayHundredths() {
      return this.pad(Math.floor((this.elapsedTime % 1000) / 10), 2);
    },
    showMinutes() {
      return Math.floor(this.elapsedTime / 60000) > 0;
    },
    circleState() {
      if (this.running) {
        return 'flashing-orange';
      } else if (this.holdStart) {
        return this.resetPending ? 'solid-green' : 'solid-white';
      } else {
        return 'solid-orange';
      }
    }
  },
  watch: {
    manualTime(newValue) {
      const time = newValue.replace(/\D/g, '');
      if (time.length > 6) {
        this.manualTime = time.slice(0, 6);
      }
    },
  },
  methods: {
    getTime() {
      // Return the current time on the timer
      return this.displayMinutes + ':' + this.displaySeconds + '.' + this.displayHundredths;
    },
    startTimer() {
      if (!this.running) {
        // console.log("Starting timer...");
        this.running = true;
        this.startTime = performance.now() - this.elapsedTime;
        this.timerInterval = setInterval(this.tick, 10);
        this.awaitingStart = false;
      }
    },
    stopTimer() {
      if (this.running) {
        // console.log("Stopping timer...");
        this.running = false;
        clearInterval(this.timerInterval);
        this.expectingStop = false;
      }
    },
    tick() {
      this.elapsedTime = performance.now() - this.startTime;
    },
    resetTimer() {
      // console.log("Resetting timer...");
      this.elapsedTime = 0;
      this.startTime = null;
      this.running = false;
      clearInterval(this.timerInterval);
    },
    pad(number, length = 2) {
      return number.toString().padStart(length, '0');
    },
    handleKeydown(event) {
      if (event.code === 'Space' && !this.keyPressed) {
        // console.log("Key down: Space");
        this.keyPressed = true;
        if (this.running) {
          this.stopTimer();
          this.expectingStop = true;
        } else if (!this.running && !this.holdStart) {
          this.holdStart = true;
          this.resetPending = false;
          this.holdTimeout = setTimeout(() => {
            this.resetTimer();
            this.resetPending = true;
          }, 500); // Change hold down requirement to 0.5 seconds
        }
      }
    },
    handleKeyup(event) {
      if (event.code === 'Space') {
        // console.log("Key up: Space");
        this.keyPressed = false;
        if (this.holdStart && !this.resetPending) {
          clearTimeout(this.holdTimeout);
          this.holdStart = false;
          return; // Do nothing if the hold threshold was not met
        }
        if (!this.running && this.awaitingStart && !this.expectingStop && this.resetPending) {
          this.startTimer();
        }
        this.awaitingStart = !this.running;
        this.expectingStop = false;
        this.holdStart = false;
      }
    },
    handleTouchstart(event) {
      //check to see that the touch is happening on the actual timer display. 

        // console.log("Touch start");
        if (this.running) { //IF THE TIMER IS RUNNING, STOP THE TIMER AND SET EXPECTING STOP TO TRUE
          this.stopTimer(); // run the stopTimer Function
          this.$el.classList.remove('full-screen'); //take away the full screen
          this.expectingStop = true; //NOT SURE ABOUT THIS LINE. - WHAT DOES IT DO? 
          this.justDidTime = true;
        } else if (!this.running && !this.holdStart) { //THE TIMER IS NOT RUNNING and holdStart is false  SO USE THIS TOUCH TO SET THE HOLD TIMER START 
          if (event.touches.length === 1 && this.isWithinBounds(event.touches[0])) {  // if it is in the bounds. 
            this.holdStart = true;
            this.resetPending = false;
            this.holdTimeout = setTimeout(() => {
              this.resetTimer();
              this.justDidTime = false; // a fresh time hasn't just been lodged, this is a reset. So change justDidTime to false. 
              this.resetPending = true;
            }, 500); // Change hold down requirement to 0.5 seconds
          }
        }
    },
    isWithinBounds(touch) {
      const rect = this.$el.getBoundingClientRect(); //bounding rectangle of the whole element. 
      return touch.clientX >= rect.left && touch.clientX <= rect.right && touch.clientY >= rect.top && touch.clientY <= rect.bottom;
    },
    handleTouchend() {
      // console.log("Touch end");
      if (this.holdStart && !this.resetPending) { //THE TIMER HOLD WASNT LONG ENOUGH
        clearTimeout(this.holdTimeout);
        this.holdStart = false;
        return; // Do nothing if the hold threshold was not met
      }
      if (!this.running && this.awaitingStart && !this.expectingStop && this.resetPending) { //IF THE TIMER ISN'T ALREADY RUNNING, START IT. 
        if(!this.justDidTime){ //a time has just been lodged already, so only start the timer in this instance if a time hasn't just been lodged. 
          this.startTimer();
          this.$el.classList.add('full-screen');
        }
        
      }

      this.awaitingStart = !this.running; // when the hold down is release, set awaitingStart to be the opposite of whether it is running so if its running awaitingStart will become false, if its not running, awaitingStart will become true.

      this.expectingStop = false; //set "expecting Stop to false". We use expecting stop ot check before starting the timer. If expectingstop is true, it wont start the timer. If it is false it will start the timer. ?
      this.holdStart = false; //set hold start to false, why? 
    },
    startEditing() {
      this.editing = true;
      this.manualTime = '';
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    stopEditing() {
      this.editing = false;

      const time = this.manualTime.replace(/\D/g, '');

      if (time.length === 0) {
        this.elapsedTime = 0;
      } else {
        let minutes, seconds, milliseconds;

        if (time.length <= 2) {
          // If time only has milliseconds
          milliseconds = time;
          seconds = 0;
          minutes = 0;
        } else if (time.length <= 4) {
          // If time only has seconds and milliseconds
          milliseconds = time.slice(-2);
          seconds = time.slice(0, -2);
          minutes = 0;
        } else {
          // If time has minutes, seconds, and milliseconds
          milliseconds = time.slice(-2);
          seconds = time.slice(-4, -2);
          minutes = time.slice(0, -4);
        }

        this.elapsedTime = (parseInt(minutes) * 60000) + (parseInt(seconds) * 1000) + (parseInt(milliseconds) * 10);
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);
    window.addEventListener('touchstart', this.handleTouchstart);
    window.addEventListener('touchend', this.handleTouchend);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
    window.removeEventListener('touchstart', this.handleTouchstart);
    window.removeEventListener('touchend', this.handleTouchend);
  },
  
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sometype+Mono:wght@500&display=swap');

.timer {
  font-size: 2em;
  font-family: 'Sometype Mono', monospace;
  color: #ff3900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.display {
  background-color: transparent;
  color: #1697B7;
  padding: 10px;
  border-radius: 10px;
  border: 5px solid #1697B7;
  width: 8.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Add this to position the circle inside the display */
  user-select: none;
}

.input-wrapper {
  background-color: transparent;
  color: #1697B7;
  padding: 10px;
  border-radius: 10px;
  border: 5px solid #1697B7;
  width: 8.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
}

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.solid-orange {
  background-color: #ffb18e;
}

.solid-white {
  background-color: white;
}

.solid-green {
  background-color: green;
}

.flashing-orange {
  background-color: #ffb18e;
  animation: flash 1s infinite;
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.reset-link {
  margin-top: 10px;
  color: #1697B7;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.4em;
  user-select: none;
}

.reset-link:hover {
  text-decoration: underline;
}


.timer-input {
  font-size: 1.0em;
  text-align: center;
  border: none;
  padding: 5;
  margin: 5;
  width: 90%;
}

.timer-input:focus {
  caret-color: #1697B7; /* Match the color of the timer */
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* or some other high value to ensure it's on top */
  background-color: white;

}
</style>
