// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import ConvertForm from '@/components/ConvertForm.vue';
import AboutPage from '@/components/AboutPage.vue';
import LiveLeader from '@/components/LiveLeader.vue';
import LivePage from '@/components/Live.vue';

const routes = [
  {
    path: '/',
    name: 'ConvertForm',
    component: ConvertForm,
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/live-leader',
    name: 'LiveLeader',
    component: LiveLeader,
    beforeEnter: () => {
      console.log('Route /live-leader resolved')
    }
  },
  {
    path: '/live',
    name: 'LivePage',
    component: LivePage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
