<template>
  <div class="about-container">

    
    <div v-if="hasCode">
      <div class="section-container">
        <h1>Current Live Session : {{ sessionName }}</h1>
      <button @click="removeCode" class="new-button-design">Exit Session</button>
      </div>
      

      
      <div class="section-container my-section">
        <h1>Current Wormble</h1>
        <div class="current-wormble">
          <div class="auto-update-toggle">
            Auto Update 
            <svg @click="toggleAutoUpdate" width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" :style="{ fill: autoUpdate ? 'orange' : '#ccc' }">
              <rect x="0" fill="none" width="20" height="20"/>
              <path d="M5.7 9c.4-2 2.2-3.5 4.3-3.5 1.5 0 2.7.7 3.5 1.8l1.7-2C14 3.9 12.1 3 10 3 6.5 3 3.6 5.6 3.1 9H1l3.5 4L8 9H5.7zm9.8-2L12 11h2.3c-.5 2-2.2 3.5-4.3 3.5-1.5 0-2.7-.7-3.5-1.8l-1.7 1.9C6 16.1 7.9 17 10 17c3.5 0 6.4-2.6 6.9-6H19l-3.5-4z"/>
            </svg>
          </div>
          <div class="triangle-icon up-icon" @click="moveUp" :class="{ disabled: focusedIndex === 0 }">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M7.41 15.41L12 10.84l4.59 4.58L18 14l-6-6-6 6z" />
            </svg>
          </div>
          <hr style="width: 80%; border-top: 1px solid #ccc; margin-bottom: 10px;">
          <table>
            <tr v-if="sessionData[focusedIndex]">
              <td>
                <span style="font-weight: bold">{{ sessionData[focusedIndex].word }}</span><br>
                {{ sessionData[focusedIndex].scramble }}
              </td>
              <td>
                <div class="star" :class="{ 'star-on': sessionData[focusedIndex].starred }" @click="toggleStar(focusedIndex)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" width="30" height="30">
                    <path xmlns="http://www.w3.org/2000/svg" style="" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"/>
                  </svg>
                </div>
              </td>
            </tr>
          </table>
          <hr style="width: 80%; border-top: 1px solid #ccc; margin-top: 10px;">
          <div class="triangle-icon down-icon" @click="moveDown" :class="{ disabled: focusedIndex === sessionData.length - 1}">
            <svg width="24" height="24" viewBox="0 0 24 24" >
              <path d="M7.41 7.84L12 13.42l4.59-4.58L18 9l-6 6-6-6z" />
            </svg>
          </div>
          <div class='user-message'>{{ userMessage }}</div><br>
          <div class='timer-info'>Like the scramble? Star it by clicking / touching the star icon</div>
          <div class='timer-info'>New scramble coming before you finish? Toggle "Auto Update" using the button above</div>

        </div>
      </div>
      <div v-if="timerEnabled" class="section-container">
          <h1>Timer</h1>
          <br>
          <TimerComponent ref="timerComponent"/>
          <br>
          <div class="center-button">
            <button @click="submitTime" class="new-button-design">
              Submit Time For <b> {{ selectedWord }} </b>...
            </button>          
       
          </div>
          <div class='timer-message'>{{ timerMessage }}</div><br>
          <div class='timer-info'>Double Tap/Click timer for manual Entry.</div>
          <div class='timer-info'>Hold spacebar / hold touch to start timer. Tap spacebar or touch to stop.</div>

      </div>
    </div>
      
    <div v-if="!hasCode" class="section-container">
      <input v-model="code" placeholder="Enter Session Code" class="input-field"/>
      <button @click="saveCode" class="new-button-design">Save Code</button>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import TimerComponent from './TimerComponent.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: { TimerComponent },
  props: {
    timerEnabled: {
      type: Boolean,
      default: true
    }
  },
  name: 'AboutPage',
  data() {
    return {
      imageSrc: require('@/assets/WormbleRe.png'),
      sessionData: [],
      focusedIndex: null,
      rowHeight: 30, // adjust this value to match the height of your table rows
      hasCode: false,
      code: '',
      selectedWord: '',
      sessionName: '',
      autoUpdate: localStorage.getItem('autoUpdate') === 'true' || localStorage.getItem('autoUpdate') === null ? true : false,
      userMessage: '',
      timerMessage: '',
    };
  },
  mounted() {
    const sessionCode = localStorage.getItem('sessionCode');
    this.getSessionName(sessionCode);

    const storedCode = localStorage.getItem('sessionCode');
    console.log('Mounted, so getting stored sessioncode', storedCode);

    if (storedCode) {
      this.hasCode = true;
    }
    
    // WEBSOCKET CONNECTIVITY IN MOUNTED. 
    
    console.log(`${process.env.VUE_APP_WS_URL}`);
    //this.ws = new WebSocket(`${process.env.VUE_APP_BASE_URL.replace(/^https?:\/\//, process.env.NODE_ENV === 'production' ? 'wss://' : 'ws://')}/ws`);

    this.ws = new WebSocket(`${process.env.VUE_APP_WS_URL}/`);

    this.ws.onopen = () => {
      console.log('WebSocket connection established');
      console.log('WebSocket readyState:', this.ws.readyState);
      this.fetchSessionData();

    };
    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'NewLiveData') {
        console.log('Received NewLiveData message:');
        this.fetchSessionData();
        this.userMessage = 'New Word Added';
        this.userMessage = 'New Word Added';
        setTimeout(() => {
          this.userMessage = '';
        }, 10000);
      }
    };
    this.ws.onerror = (error) => {
      console.log('WebSocket error:', error);
      console.log('WebSocket readyState:', this.ws.readyState);
    };
    this.ws.onclose = (event) => {
      console.log('WebSocket connection closed:', event.code, event.reason);
    };
    //END WEBSOCKET CONNECTIVITY IN MOUNTED

    //setTimeout(() => {
          //this.focusedIndex = this.sessionData.length - 1;
        //}, 200); //load the last 
        console.log('First Load Happening:');
        this.fetchSessionData();
        setTimeout(() => {
          this.focusedIndex = this.sessionData.length - 1;
          if (this.$refs.tableContainerRef) {
            this.$refs.tableContainerRef.scrollTop = this.$refs.tableContainerRef.scrollHeight;
          } 
        }, 100);
  },
  
  
  watch: {
    focusedIndex: function(newValue) {
      if (this.sessionData.length > 0 && newValue !== null && newValue >= 0 && newValue < this.sessionData.length) {
        const selectedWord = this.sessionData[newValue].word;
        this.updateSelectedWord(selectedWord);
      }
    },
    sessionData() {
      //whenever the sessionData is updated. Check if autoUpdate is on. If it is. Set the index to the last in the file. 
      if (this.autoUpdate) {
        // Update the table to the last row
        this.focusedIndex = this.sessionData.length - 1;
      //this.centerSelectedRow();
      } else {
        // Don't update the table, but flash the down arrow
        //if it is not, just 
        this.flashDownArrow = true;
        setTimeout(() => {
          this.flashDownArrow = false;
        }, 500);
      }
    },
  },
  methods: {

    toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      localStorage.setItem('autoUpdate', this.autoUpdate);
      console.log('Auto update is now:', this.autoUpdate);

    },
    async getSessionName(sessionCode) {
      try {
        const response = await axios.post('/get-session-name', { sessionCode });
        this.sessionName = response.data.sessionName;
      } catch (error) {
        console.error('Error fetching session name:', error);
      }
    },
    
    toggleStar(index) {
      if (this.sessionData[index].starred){
        //do nothing, the star is already toggled on. 
      }else{
        //do the toggle and submit the info to userdata
        this.sessionData[index].starred = !this.sessionData[index].starred;
        //submit user data. 
        const userCode = this.getUserCode();
        const sessionCode = localStorage.getItem('sessionCode');
        const dataType = 'favourites';
        const dataIsForWord = this.sessionData[this.focusedIndex].word;
        const extraData = '';
        axios.post('/update-user-data', {
          userCode,
          sessionCode,
          dataType,
          dataIsForWord,
          extraData,
        });

      }
    },
    updateSelectedWord(word) {
      this.selectedWord = word.substring(0, 10);
    },
    submitTime() {
      if (!this.sessionData || !this.sessionData[this.focusedIndex]) {
        this.timerMessage = 'No word selected';
        setTimeout(() => {
          this.timerMessage = '';
        }, 10000);
        return;
      }

      const time = this.$refs.timerComponent.getTime();
      console.log(time);
      const parts = time.split(':');
      const minutes = parseInt(parts[0]) * 60 * 1000;
      const secondsAndMilliseconds = parts[1].split('.');
      const seconds = parseInt(secondsAndMilliseconds[0]) * 1000;
      const milliseconds = parseInt(secondsAndMilliseconds[1]) * 10;
      const totalTime = minutes + seconds + milliseconds;
      console.log(totalTime);
      const userCode = this.getUserCode();
      const sessionCode = localStorage.getItem('sessionCode');
      const dataType = 'time';
      const dataIsForWord = this.sessionData[this.focusedIndex].word;
      const extraData = totalTime;

      axios.post('/update-user-data', {
        userCode,
        sessionCode,
        dataType,
        dataIsForWord,
        extraData,
      })
        .then(() => {
          this.timerMessage = 'Time Submitted';
          setTimeout(() => {
            this.timerMessage = '';
          }, 10000);
        })
        .catch((error) => {
          console.error('Error updating user data:', error);
        });
    },

    getUserCode() {
      //localStorage.removeItem('userCode'); // I just used this to erase existing bad userCode that was too long
      const storedUserCode = localStorage.getItem('userCode');
      if (storedUserCode) {
        return storedUserCode;
      } else {
        const newUserCode = uuidv4().substring(0, 8);
        localStorage.setItem('userCode', newUserCode);
        return newUserCode;
      }
    },
    saveCode() {
      localStorage.setItem('sessionCode', this.code);
      this.hasCode = true;
      this.fetchSessionData();
      this.getSessionName(this.code);
    },
    removeCode() {
      localStorage.removeItem('sessionCode');
      this.hasCode = false;
    },
    goToHomePage() {
      this.$router.push({ name: 'ConvertForm' });
    },

    async fetchSessionData() {
      try {
        const storedCode = localStorage.getItem('sessionCode');
        const response = await axios.post('/latest-session-data', { sessionCodeToGet: storedCode });
        this.sessionData = response.data.map(item => ({ ...item, starred: false }));
        if (this.autoUpdate) {
          this.focusRow(this.sessionData.length - 1);
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
      if (this.sessionData.length === 0) {
        this.userMessage = 'No words in this Session yet. Please Wait...';
      } else if (this.userMessage === 'No words in this Session yet. Please Wait...') {
        this.userMessage = '';
      }
    },
    handleWheel(event) {
      event.preventDefault();
      if (event.deltaY > 0) {
        // scroll down
        if (this.focusedIndex < this.sessionData.length - 1) {
          this.focusedIndex++;
        }
      } else {
        // scroll up
        if (this.focusedIndex > 0) {
          this.focusedIndex--;
        }
      }
      //this.centerSelectedRow();
    },
    focusRow(index) {
      if (!this.autoUpdate) return;
      this.focusedIndex = index;
      console.log(`the focused index is `, this.focusedIndex);
      //this.centerSelectedRow();
    },

    /*
    centerSelectedRow() {
      if (!this.$refs.tableContainerRef) return;
      console.log('centerSelectedRow called', this.autoUpdate);

        const table = this.$refs.tableContainerRef.querySelector('table');
        const tbody = table.querySelector('tbody');
        const selectedRow = tbody.children[this.focusedIndex];
        if (!selectedRow) return; // Add this check
        
        //if (this.autoUpdate && this.autoUpdate === true) {
          const selectedRowTop = selectedRow.offsetTop;
          const tableContainerHeight = this.$refs.tableContainerRef.offsetHeight;
          const selectedRowHeight = selectedRow.offsetHeight;
          const targetScrollTop = selectedRowTop - (tableContainerHeight / 2) + (selectedRowHeight / 2);
          this.$refs.tableContainerRef.scrollTop = targetScrollTop;
        //}
    },
*/

    moveUp() {
      if (this.focusedIndex > 0) {
        this.focusedIndex--;
        //this.centerSelectedRow();
      }
    },

    moveDown() {
      if (this.focusedIndex < this.sessionData.length - 1) {
        this.focusedIndex++;
        //this.centerSelectedRow();
      }
    }
  },
};
</script>

<style scoped>
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}



h1 {
  color: #313131; /* Bright orange color */
  margin-bottom: 20px;
}

p {
  color: #ffb18e; /* Lighter orange color */
  font-size: 1.1em;
  text-align: center;
  max-width: 600px;
}

.table-container {
  position: relative;
  width: 100%;
  height: 300px; /* assuming 3 rows of 50px each */
  overflow-y: scroll;
  scrollbar-width: none; /* hide scrollbar */
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

td {
  border: none;
  padding: 10px;
  text-align: center;
  height: 30px; /* set row height */
}

.current-wormble table td {
  font-size: 1.5em;
}

.star svg path {
  fill: none;
  stroke: #ffb18e;
  stroke-width: 2;
}

.star-on svg path {
  fill: #ffb18e;
}

.new-button-design {
  padding: 10px 20px;
  background-color: #1697B7;
  color: #ffffff; /* Dark grey text color */
  border: 2px solid #424242;
  border-radius: 5px;
  font-size: 1.0em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  margin: 0 5px; /* Add margin to separate the buttons */
}

.center-button {
  display: flex;
  justify-content: center;
}

.section-container {
  max-width: 90%; /* adjust this value as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto; /* adjust the top and bottom margins as needed */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.section-container h1 {
  margin-top: 0;
  font-size: 1.6em;

}

.triangle-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 10px auto;
}

.triangle-icon svg {
  fill: #1697B7;
}

.triangle-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.triangle-icon.disabled svg {
  fill: #ccc;
}
.current-wormble table {
  table-layout: fixed;
  width: 100%;
}

.current-wormble table td:nth-child(1) {
  width: 80%; /* word and scramble column */
}

.current-wormble table td:nth-child(2) {
  width: 20%; /* star column */
}

.current-wormble {
  max-width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
}

.my-section {
  position: relative;
}

.auto-update-toggle {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
}

.auto-update-toggle svg {
  margin-left: 10px;
}
.auto-update-toggle .new-data-available {
  margin-left: 10px;
  font-size: 12px;
  color: #999;
}

.user-message {
  color: orange;
  align-items: center;
  justify-content: center;
  display: flex;

}

.timer-message {
  color: orange;
  align-items: center;
  justify-content: center;
  display: flex;

}

.timer-info {
  color: rgb(49, 49, 49);
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 10px;

}

.input-field {
  width: calc(100% - 10px);  /*Adjust width to fill the container with some padding */
  max-width: 500px; /* Maximum width for larger screens */
  padding: 10px;
  margin: 0 auto; /* Add this line */
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.0em;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  position: relative;
}

</style>