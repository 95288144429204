<template>
  <div class="about-container">
    <div class="section-container"> 
    <h1>About Wormble</h1>
    <p>This is a simple fun tool to create a 3x3 scramble out of a word. The scrambles are generated using an in house algorithm that is based on random moves, not random state. This means scramble integrity will NOT be as high as competition scrambles, though for our purpose - fun and sharing - it does the job nicely. <br> <br>If you want to support the creator "swiftcubing" then please visit some of the links to Youtube / Instagram / Cubicle on his main site swiftcubing.com.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  data() {
    return {
      imageSrc: require('@/assets/WormbleRe.png'),
    };
  },
  methods: {
    goToHomePage() {
      this.$router.push({ name: 'ConvertForm' });
    },
  },
};
</script>

<style scoped>
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}


h1 {
  color: #1697B7; /* Bright orange color */
  margin-bottom: 20px;
}

p {
  color: #000000; /* Lighter orange color */
  font-size: 1.1em;
  text-align: center;
  max-width: 600px;
}

.section-container {
  max-width: 90%; /* adjust this value as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto; /* adjust the top and bottom margins as needed */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.section-container h1 {
  margin-top: 0;
  font-size: 1.6em;

}
</style>
